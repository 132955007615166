import React, { useState, useEffect } from 'react';

const Hackathon = () => {
    const [iframeStyle, setIframeStyle] = useState({
        width: '100%',
        height: '100vh',
        border: 'none',
        zoom: '1.2'  // Default zoom
    });

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 420 && window.innerWidth <= 450) {
                setIframeStyle(prevStyle => ({
                    ...prevStyle,
                    zoom: '1.3'
                }));
            } else if (window.innerWidth < 420) {
                setIframeStyle(prevStyle => ({
                    ...prevStyle,
                    zoom: '1.2'
                }));
            } else {
                setIframeStyle(prevStyle => ({
                    ...prevStyle,
                    zoom: '1'  // Reset zoom for wider screens if needed
                }));
            }
        };

        // Set initial zoom based on current window size
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Clean up event listener
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <iframe 
            src="https://pijo53.wixsite.com/pilhackatonintern24"
            style={iframeStyle}
            title="Embedded Webpage"
        >
            Your browser does not support iframes.
        </iframe>
    );
};

export default Hackathon;
