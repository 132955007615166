// import logo from './logo.svg';
import React, { useEffect } from 'react';
import './App.css';
// import PluginLiveLogo from './Assets/Icons/PluginLiveLogo';
import Header from './Components/Header/Header';

import Footer from './Components/Footer/Footer';

import Footerpage from './Components/Footer/footerpage';

import Fresher from './Components/Fresher/fresher';
import College from './Components/Colleges/colleges';
import Corporate from './Components/Corporate/corporate';
import Experienced from './Components/Experienced/experienced';
import Partner from './Components/Partner/partner';
import Privacy from './Components/PrivacyPolicy/privacypolicy';
import Terms from './Components/TermsandConditions/termsandconditions';
import Faq from './Components/Faqs/faqs';
import Security from './Components/PrivacyandSecurity/privacyandsecurity';
// import Joblisting from './Components/Joblisting/joblisting';
import LandingPage from './Components/LandingPage/landing';
import Jobs from "./Components/Jobs";
import ViewRole from "./Components/ViewRole";
import BlogLanding from "./Components/BlogLandingpage/BlogLandingPage";
import AboutUs from "./Components/AboutUs2/aboutus2";
import Form from "./Components/StudentForm/studentform";

import Mainlandingpage from './Components/MainLandingPage/MainLogoLanding';
import RequestDemo from './Components/RequestDemo/Requestdemo';

import Landingpg from './Components/LandingPG/landingpg';
import Hackathon from './Components/Hackathon'
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import {
  useParams,
  BrowserRouter as Router,Routes,
  Route
} from "react-router-dom";




function App() {
  useEffect(() => {
    console.log(window.location.pathname,'check')
  },[])
  const ddd = window.location.pathname;
  console.log(ddd,'path');

  // const Lay = ({ children }) => (
  //   <div>
  //     {/* Header */}
  //     <Header/>
  
  //     {/* Main Content */}
  //     {children}
  
  //     {/* Footer */}
  //     {/* <footer>
  //       <p>&copy; 2024 Your App Name. All rights reserved.</p>
  //     </footer> */}
  //     <Footer/>
  //   </div>
  // );

  return (
    <div className="App">


   {ddd !== '/' && ddd !== '/pluginLiveHackathon2024' &&   <Header/>}
     <Router>
        <Routes>
        <Route  path="/" element={<Mainlandingpage/>}></Route>
        <Route  path="/partner" element={<Partner/>}></Route>
        <Route  path="/experienced" element={<Experienced/>}></Route>
        <Route  path="/corporate" element={<Corporate/>}></Route>
        <Route  path="/college" element={<College/>}></Route>
        <Route path="/fresher" element={<Fresher/>}></Route>
        <Route path="/joblisting" element={<Jobs/>}></Route>
        <Route path="/privacypolicy" element={<Privacy/>}></Route>
        <Route path="/Terms_And_Conditions" element={<Terms/>}></Route>
        <Route path="/privacyandsecurity" element={<Security/>}></Route>
        <Route path="/faqs" element={<Faq/>}></Route>
        <Route path="/BlogLanding" element={<BlogLanding/>}></Route>
        <Route path="/AboutUs" element={<AboutUs/>}></Route>
        <Route  path="Job/:CorpID/:JobID" element={<ViewRole />} />
        <Route  path="/Student_Registration" element={<Form />} />
        <Route  path="/pluginLiveHackathon2024" element={<Hackathon />} />
        {/* <Route  path="/newlanding" element={<Landingpg />} /> */}
        </Routes>
      </Router>
    {ddd !== '/' && ddd !== '/pluginLiveHackathon2024'  &&  <Footerpage/>}
    
    
    </div>
  );
}

export default App;
